var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "900px",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "5vh",
            title:
              _vm.feedBackDetail.type +
              ": " +
              _vm.feedBackDetail.createdBy +
              " - " +
              _vm.feedBackDetail.hidden,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "feedBackDetail",
              attrs: {
                "label-width": "140px",
                model: _vm.feedBackDetail,
                "label-position": "left",
                rules: _vm.rules,
              },
            },
            [
              _c("span", { staticStyle: { float: "right" } }, [
                _vm._v(
                  _vm._s(
                    _vm._f("formatDateTime")(_vm.feedBackDetail.createdDate)
                  )
                ),
              ]),
              _c(
                "el-row",
                { attrs: { gutter: 40 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "Tiêu đề", prop: "feedbackTitle" },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "Nhập tiêu đề" },
                              model: {
                                value: _vm.feedBackDetail.feedbackTitle,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.feedBackDetail,
                                    "feedbackTitle",
                                    $$v
                                  )
                                },
                                expression: "feedBackDetail.feedbackTitle",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Nội dung",
                              prop: "feedbackContent",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                rows: 10,
                                placeholder: "Nhập nội dung",
                              },
                              model: {
                                value: _vm.feedBackDetail.feedbackContent,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.feedBackDetail,
                                    "feedbackContent",
                                    $$v
                                  )
                                },
                                expression: "feedBackDetail.feedbackContent",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Ảnh đính kèm",
                              prop: "attachFile",
                            },
                          },
                          [
                            _c("el-upload", {
                              ref: "upload",
                              attrs: {
                                action: "",
                                "on-remove": _vm.handleRemove,
                                "before-remove": _vm.beforeRemove,
                                "auto-upload": false,
                                multiple: "",
                                limit: 5,
                                "on-exceed": _vm.handleExceed,
                                "file-list": _vm.feedBackDetail.fileList,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "Nhà trường trả lời" } },
                          [
                            _vm.feedBackDetail.schoolReplyDel
                              ? _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("Nội dung đã được thu hồi"),
                                ])
                              : _c("el-input", {
                                  attrs: { type: "textarea", rows: 5 },
                                  model: {
                                    value: _vm.feedBackDetail.schoolReply,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.feedBackDetail,
                                        "schoolReply",
                                        $$v
                                      )
                                    },
                                    expression: "feedBackDetail.schoolReply",
                                  },
                                }),
                            _vm.feedBackDetail.feedBackDel
                              ? _c("span", [_vm._v("Đã thu hồi")])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("span", { staticStyle: { float: "right" } }, [
                          _vm._v(
                            "Xác nhận :" +
                              _vm._s(
                                _vm._f("formatDateTime")(
                                  _vm.feedBackDetail.confirmDate
                                )
                              ) +
                              " -\n              " +
                              _vm._s(_vm.feedBackDetail.confirmName) +
                              "   |   Trả lời:" +
                              _vm._s(
                                _vm._f("formatDateTime")(
                                  _vm.feedBackDetail.schoolTimeReply
                                )
                              ) +
                              "\n              - " +
                              _vm._s(_vm.feedBackDetail.replyName)
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }