var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "900px",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "5vh",
            title: "Tạo góp ý ẩn danh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "feedBackHidden",
              attrs: {
                "label-width": "140px",
                model: _vm.feedBackHidden,
                "label-position": "left",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 40 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "Tiêu đề", prop: "feedbackTitle" },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "Nhập tiêu đề" },
                              model: {
                                value: _vm.feedBackHidden.feedbackTitle,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.feedBackHidden,
                                    "feedbackTitle",
                                    $$v
                                  )
                                },
                                expression: "feedBackHidden.feedbackTitle",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Nội dung",
                              prop: "feedbackContent",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                placeholder: "Nhập nội dung",
                                rows: 10,
                              },
                              model: {
                                value: _vm.feedBackHidden.feedbackContent,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.feedBackHidden,
                                    "feedbackContent",
                                    $$v
                                  )
                                },
                                expression: "feedBackHidden.feedbackContent",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "Chọn trường", prop: "idSchool" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "button-left",
                                attrs: {
                                  placeholder: "Chọn trường",
                                  clearable: "",
                                },
                                on: { change: _vm.handleChangeSelect },
                                model: {
                                  value: _vm.feedBackHidden.idSchool,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.feedBackHidden,
                                      "idSchool",
                                      $$v
                                    )
                                  },
                                  expression: "feedBackHidden.idSchool",
                                },
                              },
                              _vm._l(_vm.schoolInAgentList, function (item) {
                                return _c(
                                  "el-option",
                                  {
                                    key: item.id,
                                    attrs: {
                                      label: item.schoolName,
                                      value: item.id,
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(item.schoolName)),
                                    ]),
                                  ]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: {
                              display: "inline-block",
                              width: "30%",
                            },
                            attrs: { label: "Đối tượng nhận" },
                          },
                          [_c("span", [_vm._v("Phụ huynh")])]
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { float: "right", width: "70%" },
                            attrs: { label: "Loại góp ý" },
                          },
                          [_c("span", [_vm._v("Ẩn danh")])]
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Ảnh đính kèm",
                              prop: "attachFile",
                            },
                          },
                          [
                            _c(
                              "el-upload",
                              {
                                ref: "upload",
                                attrs: {
                                  action: "",
                                  "on-remove": _vm.handleRemove,
                                  "before-remove": _vm.beforeRemove,
                                  "auto-upload": false,
                                  multiple: "",
                                  "on-change": _vm.toggleUpload,
                                  limit: 5,
                                  "on-exceed": _vm.handleExceed,
                                  "file-list": _vm.fileList,
                                  "on-preview": _vm.handleFileRequest,
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  { attrs: { size: "mini", type: "primary" } },
                                  [_vm._v("Tải lên")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tab-infor-button" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialogByButton("feedBackHidden")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _c("span", [_vm._v("Đóng")]),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "success",
                    size: "medium",
                    loading: _vm.loadingButton,
                    mini: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("feedBackHidden")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-check" }),
                  _c("span", [_vm._v("Lưu")]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }